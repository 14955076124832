import React from 'react'
import { Heading, Flex, Box } from '@primer/components'
import styled from 'styled-components'

import PageSetup from '../components/layout/PageSetup'
import LinkLight from '../components/reusable/LinkLight'

const errorDescription = 'Page Not Found'

const ErrorText = styled(Heading)`
  letter-spacing: 0.05em;
`

const NotFoundPage = () => (
  <PageSetup siteTitle={errorDescription} justifyContent="center">
    <Flex
      as={Box}
      maxWidth="350px"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      my={12}
      py={[0, 4]}
    >
      <Heading
        as="h2"
        fontSize={['100px', '150px']}
        color="primary"
        fontWeight="normal"
        fontFamily="mono"
        lineHeight="condensedUltra"
        mb={2}
      >
        404
      </Heading>
      <ErrorText as="h1" fontWeight="bold" mb={3} fontSize={[5, 6]}>
        {errorDescription}
      </ErrorText>
      <Flex as={Box} justifyContent="start" width={1}>
        <LinkLight fontSize={3} to="/">
          Go home
        </LinkLight>
      </Flex>
    </Flex>
  </PageSetup>
)

export default NotFoundPage
